<template>
  <z-main-page type="card">
    <el-form ref="formDom" :model="formData" :rules="rules" label-width="150rem">
      <div class="top-info p-2 bg-white br-md">
        <div class="top-tip mb-2"><i class="el-icon-warning text-red mr-1"></i>注意：下发后题目将会在学生端展示</div>
        <template v-if="isAdd">
          <el-row>
            <el-col span="6">
              <el-form-item label="校区" prop="school_id">
                <el-select placeholder="请输入" v-model="formData.school_id">
                  <el-option
                    v-for="item in formOptions1"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col span="6">
              <el-form-item label="年级" prop="grade_id">
                <el-select placeholder="请输入" v-model="formData.grade_id">
                  <el-option
                    v-for="item in formOptions2"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col span="7">
              <el-form-item label="教学班" prop="teaching_id">
                <div class="flex align-center">
                  <el-select
                    v-model="formData.teaching_id"
                    filterable
                    remote
                    reserve-keyword
                    placeholder="请输入"
                    :remote-method="remoteMethod"
                    :loading="searchLoading">
                    <el-option
                      v-for="item in formOptions3"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                  <el-button class="ml-2" type="text" @click="handleClick(2)">我的教学班</el-button>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col span="6">
              <el-form-item label="日期" prop="day">
                <el-date-picker
                  v-model="formData.day"
                  align="right"
                  type="date"
                  placeholder="选择日期"
                  value-format="yyyy-MM-dd"
                  :picker-options="pickerOptions">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col span="6">
              <el-form-item label="课节" prop="calendar_id">
                <el-select placeholder="请输入" v-model="formData.calendar_id">
                  <el-option
                    v-for="item in formOptions4"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </template>
        <template v-else>
          <LineWrapper class="top-list" label-width="100rem" color="#1E3AA2">
            <LineItem :label="item.label" :value="item.value" v-for="item in info" :key="item.label"></LineItem>
          </LineWrapper>
        </template>
      </div>
      <div class="text-primary my-2 fs-18 fw-bold">{{ isAdd ? '' : '已' }}添加问卷</div>
      <div class="p-2 bg-white br-md">
        <el-button v-if="isAdd" class="mb-2" @click="handleClick(1)" type="primary" plain>点此添加问卷</el-button>
        <el-form-item prop="topics" label-width="0">
          <div class="questionnaire-list" v-if="topicsList.length">
            <div v-for="(item,idx) in topicsList" :key="idx" class="questionnaire-item br-md">
              <div class="p-2">
                <div class="text-black fw-bold fs-18 mb-1">问卷名称</div>
                <div class="box line-2 text-body" :title="item.survey_name">{{ item.survey_name }}</div>
              </div>
              <div class="btn px-2 py-1" v-if="isAdd"><el-button type="danger" plain @click="handleDelete(item)">删除</el-button></div>
            </div>
          </div>
        </el-form-item>
        <t-result v-if="!topicsList.length" text="暂未添加问卷" type='empty'></t-result>
      </div>
    </el-form>
    <Dialog
      buttonAlign="center"
      :visible.sync="visible"
      :title="title"
      :submitText="submitText"
      :showCancelButton="showCancelButton"
      :showFooter="true"
      :closeOnClickModal="false"
      :beforeSubmit="onBeforeSubmit"
      @close="onClose">
      <template v-if="type === 1">
        <el-form ref="form" :model="formDialog" :rules="rulesDialog" label-width="150rem">
          <el-form-item label="问卷名称" prop="survey_id">
            <el-select
              v-model="formDialog.survey_id"
              maxlength="20"
              show-word-limit
              filterable
              remote
              placeholder="请输入"
              :remote-method="remoteSurvey"
              :loading="formLoading"
            >
            <el-option
              v-for="item in formDialogOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </template>
      <template v-else>
        <el-table border :data="tableData" style="width: 100%">
          <el-table-column prop="name" label="教学班"></el-table-column>
          <el-table-column prop="subject_name" label="科目" width="200"></el-table-column>
        </el-table>
      </template>
    </Dialog>
    <template #footer>
      <template v-if="isAdd">
        <el-button type="primary" @click="handleSubmit">提交</el-button>
        <el-button @click="handleBack">取消</el-button>
      </template>
      <template v-else>
        <el-button type="primary" @click="handleBack">返回</el-button>
      </template>
    </template>
  </z-main-page>
</template>

<script>
import Dialog from '@/components/common/Dialog'
import LineWrapper from '@/components/common/LineWrapper'
import LineItem from '@/components/common/LineItem'
import { setConfigOption, changeOption } from '@/utils'
import { get_grade3, get_school3, postApi2, getApi2 } from '@/api'
import { formDataBackMixin } from '@/utils/mixins'
export default {
  _config: {route: [{path: 'add', meta: {title: '新增'}},{path: 'detail', meta: {title: '详情'}}]},
  components: { Dialog, LineWrapper, LineItem },
  mixins: [formDataBackMixin],
  data() {
    const validatorTopics = (rule, value, callback) => {
      if (!value || !value.length) {
        callback(new Error('请添加问卷'))
      } else {
        callback()
      }
    }
    return {
      title: '',
      type: '',
      submitText: '',
      showCancelButton: false,
      visible: false,
      searchLoading: false,
      formLoading: false,
      pickerOptions: { disabledDate:time => Date.now() - 86400000 > time.getTime() },
      formOptions1: [],
      formOptions2: [],
      formOptions3: [],
      formOptions4: [],
      formDialogOptions: [],
      formData: { school_id: '', grade_id: '', teaching_id: '', day: '', calendar_id: '', topics: [] },
      rules: {
        school_id: [ { required: true, message: '请选择', trigger: 'change' } ],
        grade_id: [ { required: true, message: '请选择', trigger: 'change' } ],
        teaching_id: [ { required: true, message: '请选择', trigger: 'change' } ],
        day: [ { required: true, message: '请选择', trigger: 'blur' } ],
        calendar_id: [ { required: true, message: '请选择', trigger: 'change' } ],
        topics: [ { required: true, validator: validatorTopics, trigger: 'change' } ],
      },
      formDialog: { survey_id: '' },
      rulesDialog: {
        survey_id: [
          { required: true, message: '请选择', trigger: 'blur' }
        ]
      },
      tableData: [],
      isAdd: true,
      info: [],
      topics: []
    }
  },
  watch: {
    'formData.school_id'(val) {
      this.getTime()
      this.initClass()
      this.initSection()
    },
    'formData.grade_id'(val) {
      this.getTime()
      this.initClass()
      this.initSection()
    },
    'formData.teaching_id'(val) {
      this.getTime()
      this.initSection()
    },
    'formData.day'(val) {
      this.getTime()
      this.initSection()
    }
  },
  computed: {
    topicsList() {
      if (this.topics.length) return this.topics
      if (this.formData.topics.length) return this.formData.topics
      return []
    }
  },
  created() {
    const { id } = this.$route.query
    if (id) {
      this.isAdd = false
      this.getData(id)
    } else {
      this.getConfig()
    }
  },
  methods: {
    getConfig() {
      get_school3().then(res => {
        this.formOptions1 = changeOption({ list: res, label: 'school_name', value: 'id' })
      })
      get_grade3().then(res => {
        this.formOptions2 = changeOption({ list: res, label: 'name', value: 'id' })
      })
      getApi2('/survey/interaction/topic-class', { only_my_class: 1 }).then(res => {
        this.tableData = res
      })
    },
    getTime() {
      const {  school_id, grade_id, teaching_id, day } = this.formData
      if (!school_id || !grade_id || !teaching_id || !day) {
        this.formOptions4 = []
        return
      }
      const data = { school_id, grade_id, teaching_id, day }
      getApi2('/survey/interaction/topic-time', data).then(res => {
        this.formOptions4 = changeOption({ list: res, label: 'time', value: 'calendar_id' })
      })
    },
    initSection() {
      this.formData.calendar_id = ''
      this.formOptions4 = []
    },
    initClass() {
      this.formData.teaching_id = ''
      this.formOptions3 = []
    },
    remoteSurvey(query){
      if (!query) return
      const data = { survey_name: query }
      this.formLoading = true
      getApi2('/survey/interaction/topic-survey', data).then(res => {
        this.formDialogOptions = changeOption({ list: res, label: 'name', value: 'survey_id', all: true })
      }).finally(() => {
        this.formLoading = false
      })
    },
    remoteMethod(query){
      if (!this.formData.school_id || !this.formData.grade_id) return this.$message.warning('请先选择校区和年级')
      if (!query) return
      const data = { class_name: query, school_id: this.formData.school_id, grade_id: this.formData.grade_id }
      this.searchLoading = true
      getApi2('/survey/interaction/topic-class', data).then(res => {
        this.formOptions3 = changeOption({ list: res, label: 'name', value: 'id' })
      }).finally(() => {
        this.searchLoading = false
      })
    },
    getData(id) {
      getApi2('/survey/interaction/topic-view', { id }).then(res => {
        const list = [
          { label: '校区', value: res.school_name },
          { label: '年级', value: res.grade_name },
          { label: '教学班', value: res.class_name },
          { label: '创建人', value: res.creator },
          { label: '日期', value: res.day },
          { label: '课节', value: res.class_time },
          { label: '创建时间', value: res.created_at },
        ]
        this.info = list
        this.topics = res.topics || []
      })
    },
    handleClick(type) {
      this.type = type;
      this.visible = true;
      if (type === 1) {
        this.title = '添加问卷'
        this.submitText = '保存'
        this.showCancelButton = true
      } else if (type === 2) {
        this.title = '查看我的教学班'
        this.submitText = '关闭'
        this.showCancelButton = false
      }
    },
    handleDelete(row) {
      const idx = this.formData.topics.findIndex(item => item.id === row.id)
      this.$confirm('确定删除吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.formData.topics.splice(idx, 1)
        this.$message({ type: 'success', message: '删除成功!' })
        this.$refs.formDom.validateField(['topics'])
      }).catch(() => {})
    },
    onBeforeSubmit(done, instance) {
      if (this.type === 2) return done()
      this.$refs['form'].validate(valid => {
        if (valid) {
          instance.confirmButtonLoading = true
          setTimeout(() => {
            const data = this.formDialogOptions.find(item => item.value === this.formDialog.survey_id)
            if (!data) return this.$message.error('问卷不存在')
            this.formData.topics.push({ id: Date.now(), survey_name: data.name, form_id: data.form_id, survey_id: data.survey_id })
            this.$refs.formDom.clearValidate(['topics'])
            instance.confirmButtonLoading = false
            done()
          }, 200);
        }
      })
    },
    onClose() {
      if (this.type === 2) return
      this.$refs['form'].resetFields()
      this.formDialogOptions = []
    },
    handleSubmit() {
      this.$refs.formDom.validate().then((valid) => {
        if (valid) {
          const { topics, ...other } = this.formData
          const data = { ...other, topics: topics.map(item => ({ survey_id: item.survey_id, form_id: item.form_id })) }
          postApi2('/survey/interaction/topic-add', data).then(() => {
            this.$message.success('添加成功！')
            this.$router.back()
          }).finally(() => {
          })
        }
      })
    },
    handleBack() {
      this.formBack(this.formData)
    }
  }
}
</script>

<style lang="scss" scoped>
.top {
  &-tip {
    height: 40rem;
    padding: 0 20rem;
    line-height: 40rem;
    display: inline-block;
    background-color: #FCF0EA;
    border-radius: 2rem;
  }
  &-list {
    display: grid;
    gap: 10rem 20rem;
    grid-template-columns: repeat(auto-fill, minmax(380rem, 1fr));
  }
}
.questionnaire {
  &-list {
    display: grid;
    gap: 20rem;
    grid-template-columns: repeat(auto-fill, minmax(350rem, 1fr));
  }
  &-item {
    border: solid 1rem #eee;
    background-color: #fff;
    box-shadow: 0 1px 6px 0 rgba(83,83,83,0.1);
    .box {
      height: 60rem;
      line-height:30rem;
    }
    .btn {
      display: flex;
      justify-content: flex-end;
      background-color: #f8f8f8;
    }
  }
}
</style>
